/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectTrailersContainerDomain = state => state.trailersContainerReducer || initialState;

export const getIsTrailersListLoadingSelector = () =>
	createSelector(selectTrailersContainerDomain, ({ isTrailersListLoading }) => isTrailersListLoading);

export const getTrailersListSelector = () =>
	createSelector(selectTrailersContainerDomain, ({ trailersList }) => {
		if (!trailersList || trailersList.length === 0) {
			return [];
		}

		return trailersList.map(trailer => ({
			...trailer,
			is_blocked: !trailer.is_accredited,
		}));
	});

export const getPaginationSelector = () =>
	createSelector(selectTrailersContainerDomain, ({ pagination }) => {
		if (!pagination || pagination?.total === 0) {
			return null;
		}

		return {
			currentPage: pagination.current_page,
			totalPages: pagination.last_page,
			perPage: pagination.per_page,
			total: pagination.total,
			from: pagination.current_page * pagination.per_page - (pagination.per_page - 1),
			to:
				pagination.current_page === pagination.last_page
					? pagination.total
					: pagination.current_page * pagination.per_page,
			isNextItem: true,
			isPrevItem: true,
			isLastItem: false,
			isFirstItem: false,
		};
	});

export const getTableSearchSelector = () =>
	createSelector(selectTrailersContainerDomain, ({ tableSearch }) => tableSearch);

export const getRawPaginationSelector = () =>
	createSelector(selectTrailersContainerDomain, ({ pagination }) => pagination);
