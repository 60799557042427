/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectCarsContainerDomain = state => state.carsContainerReducer || initialState;

export const getIsCarsListLoadingSelector = () =>
	createSelector(selectCarsContainerDomain, ({ isCarsListLoading }) => isCarsListLoading);

export const getCarsListSelector = () =>
	createSelector(selectCarsContainerDomain, ({ carsList }) => {
		if (!carsList || carsList.length === 0) {
			return [];
		}

		return carsList.map(car => ({
			...car,
			is_blocked: !car.is_accredited,
		}));
	});

export const getPaginationSelector = () =>
	createSelector(selectCarsContainerDomain, ({ pagination }) => {
		if (!pagination || pagination?.total === 0) {
			return null;
		}

		return {
			currentPage: pagination.current_page,
			totalPages: pagination.last_page,
			perPage: pagination.per_page,
			total: pagination.total,
			from: pagination.current_page * pagination.per_page - (pagination.per_page - 1),
			to:
				pagination.current_page === pagination.last_page
					? pagination.total
					: pagination.current_page * pagination.per_page,
			isNextItem: true,
			isPrevItem: true,
			isLastItem: false,
			isFirstItem: false,
		};
	});

export const getTableSearchSelector = () => createSelector(selectCarsContainerDomain, ({ tableSearch }) => tableSearch);

export const getRawPaginationSelector = () => createSelector(selectCarsContainerDomain, ({ pagination }) => pagination);
