/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import './styles.less';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { FormSelect } from '../../../../../semantic-ui/components/form-select';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';

export const DetailFormView = ({
	detailsOptions,
	isDetailsLoading,
	onDetailSearchChange,
	onDetailChange,
	detailsSearchValue,
	isDetailInfoLoading,
}) => {
	return (
		<div className="detail-form">
			<section className="detail-form__section">
				<div className="detail-form__row">
					<span className="detail-form__text detail-form__text_right detail-form__text_bold">Справочник</span>
					<div className="detail-form__field">
						<Field
							name="select"
							type="text"
							component={FormSelect}
							autoComplete="off"
							placeholder="Введите БИК или название банка"
							icon="search"
							options={detailsOptions}
							loading={isDetailsLoading || isDetailInfoLoading}
							noResultsMessage="Неизвестный банк"
							onSearchChange={onDetailSearchChange}
							onItemChange={onDetailChange}
							searchValue={detailsSearchValue}
						/>
					</div>
				</div>
				<div className="detail-form__row">
					<span className="detail-form__header-text">
						Вы можете заполнить реквизиты вручную или выбрать из справочника
					</span>
				</div>
				<div className="detail-form__row">
					<span className="detail-form__text detail-form__text_right">Название банка</span>
					<div className="detail-form__field">
						<Field
							name="name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите название банка"
							loading={isDetailInfoLoading}
						/>
					</div>
				</div>
				<div className="detail-form__row">
					<span className="detail-form__text detail-form__text_right">БИК</span>
					<div className="detail-form__field">
						<Field
							name="bic"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите БИК"
							loading={isDetailInfoLoading}
						/>
					</div>
				</div>
				<div className="detail-form__row">
					<span className="detail-form__text detail-form__text_right">Корр. счет</span>
					<div className="detail-form__field">
						<Field
							name="corr"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите корр. счет"
							loading={isDetailInfoLoading}
						/>
					</div>
				</div>
				<div className="detail-form__row">
					<span className="detail-form__text detail-form__text_right">Расчетный счет</span>
					<div className="detail-form__field">
						<Field
							name="account"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите расчетный счет"
							loading={isDetailInfoLoading}
						/>
					</div>
				</div>
				<div className="detail-form__row">
					<span className="detail-form__text detail-form__text_right">Использовать по умолчанию</span>
					<div className="detail-form__field">
						<Field name="is_default" component={FormCheckboxField} toggle type="checkbox" />
					</div>
				</div>
			</section>
		</div>
	);
};

DetailFormView.propTypes = {
	detailsOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isDetailsLoading: PropTypes.bool.isRequired,
	onDetailSearchChange: PropTypes.func.isRequired,
	onDetailChange: PropTypes.func.isRequired,
	detailsSearchValue: PropTypes.string.isRequired,
	isDetailInfoLoading: PropTypes.bool.isRequired,
};
