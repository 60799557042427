import React from 'react';
import { Grid } from 'semantic-ui-react';
import './styles.less';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { FormSelectField } from '../../../../../semantic-ui/components/form-select-field';
import { CustomDatePicker } from '../../../../../semantic-ui/components/custom-datepicker';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { ModesComponent } from '../../modes';

export const CarFormView = ({
	trailersList,
	isTrailersListLoading,
	isCarInfoLoading,
	tonnages,
	isTonnagesLoading,
	carTypes,
	isTypeDateIssueVisible,
	isTrailerVisible,
	modes,
	isAdmin,
	isCarTypesLoading,
	isModesLoading,
	isAvailableTrailers,
}) => {
	return (
		<Grid className="car-form">
			<Grid.Row className="car-form__row">
				<Grid.Column width={6} className="car-form__column">
					Модель
				</Grid.Column>
				<Grid.Column width={7} className="car-form__column">
					<Field
						name="name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите модель"
						loading={isCarInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="car-form__row">
				<Grid.Column width={6} className="car-form__column">
					Гос. номер
				</Grid.Column>
				<Grid.Column width={7} className="car-form__column">
					<Field
						name="number"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите гос. номер"
						loading={isCarInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="car-form__row">
				<Grid.Column width={6} className="car-form__column">
					Vin номер
				</Grid.Column>
				<Grid.Column width={7} className="car-form__column">
					<Field
						name="vin"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите vin номер"
						loading={isCarInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="car-form__row">
				<Grid.Column width={6} className="car-form__column">
					Тип машины
				</Grid.Column>
				<Grid.Column width={7} className="car-form__column">
					<Field
						name="type_id"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						options={carTypes}
						search
						dropdownClassName="static"
						loading={isCarInfoLoading || isCarTypesLoading}
						noResultsMessage="Не найдено"
						placeholder="Укажите тип машины"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="car-form__row">
				<Grid.Column width={6} className="car-form__column">
					Год выпуска машины
				</Grid.Column>
				<Grid.Column width={3} className="car-form__column">
					<Field
						name="date_issue"
						type="text"
						component={CustomDatePicker}
						autoComplete="off"
						placeholder=". . / . . "
						dateFormat={['MM.yyyy', 'MM-yyyy', 'MM/yyyy']}
						loading={isCarInfoLoading}
						showMonthYearPicker
					/>
				</Grid.Column>
			</Grid.Row>
			{isTypeDateIssueVisible && (
				<Grid.Row className="car-form__row">
					<Grid.Column width={6} className="car-form__column">
						Год выпуска рефрижератора
					</Grid.Column>
					<Grid.Column width={3} className="car-form__column">
						<Field
							name="type_date_issue"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							placeholder=". . / . . "
							dateFormat={['MM.yyyy', 'MM-yyyy', 'MM/yyyy']}
							loading={isCarInfoLoading}
							showMonthYearPicker
						/>
					</Grid.Column>
				</Grid.Row>
			)}
			{!isTrailerVisible && (
				<Grid.Row className="car-form__row">
					<Grid.Column width={6} className="car-form__column">
						Грузоподъемность
					</Grid.Column>
					<Grid.Column width={7} className="car-form__column">
						<Field
							name="tonnage_id"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							options={tonnages}
							search
							dropdownClassName="static"
							loading={isTonnagesLoading || isCarInfoLoading}
							disabled={isTonnagesLoading || isCarInfoLoading}
							noResultsMessage="Не найдено"
							placeholder="Укажите грузоподъемность прицепа (тн)"
							isWithoutSelectOnBlur
						/>
					</Grid.Column>
				</Grid.Row>
			)}
			{isTrailerVisible && (
				<Grid.Row className="car-form__row">
					<Grid.Column width={6} className="car-form__column">
						Прицеп по умолчанию
					</Grid.Column>
					<Grid.Column width={7} className="car-form__column">
						<div
							className={
								isAvailableTrailers ? 'car-form__column-with-button' : ' car-form__column_with-select'
							}
						>
							<div className={`car-form__field-with-${isAvailableTrailers ? 'button' : 'select'}`}>
								<Field
									name="trailer"
									type="text"
									component={FormSelectField}
									autoComplete="off"
									options={trailersList}
									search
									dropdownClassName="static"
									loading={isTrailersListLoading || isCarInfoLoading}
									noResultsMessage="Не найдено"
									placeholder="Укажите прицеп по умолчанию"
								/>
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>
			)}
			{isTypeDateIssueVisible && (
				<FieldArray
					name="modes"
					component={ModesComponent}
					props={{ modes, isCarInfoLoading, isModesLoading }}
				/>
			)}
			{isAdmin && (
				<Grid.Row className="car-form__row">
					<Grid.Column width={6} className="car-form__column">
						Машина аккредитована
					</Grid.Column>
					<Grid.Column width={7} className="car-form__column">
						<Field name="is_accredited" component={FormCheckboxField} toggle type="checkbox" />
					</Grid.Column>
				</Grid.Row>
			)}
		</Grid>
	);
};

CarFormView.propTypes = {
	trailersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			key: PropTypes.string.isRequired,
		}),
	),
	isTrailersListLoading: PropTypes.bool.isRequired,
	onAddTrailerButtonClick: PropTypes.func.isRequired,
	isCarInfoLoading: PropTypes.bool.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	carTypes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isTypeDateIssueVisible: PropTypes.bool.isRequired,
	isTrailerVisible: PropTypes.bool.isRequired,
	modes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isAdmin: PropTypes.bool.isRequired,
	isCarTypesLoading: PropTypes.bool.isRequired,
	isModesLoading: PropTypes.bool.isRequired,
	isAvailableTrailers: PropTypes.bool.isRequired,
};
