import moment from 'moment';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { DATE_FORMAT } from './constants';

export const selectHeaderSearchDomain = state => state.headerSearchReducer || initialState;

export const getSearchValueSelector = () => createSelector(selectHeaderSearchDomain, ({ searchValue }) => searchValue);

export const getFoundReqsSelector = () =>
	createSelector(selectHeaderSearchDomain, ({ foundReqs }) =>
		foundReqs.map(req => ({
			key: String(req.id),
			value: String(req.id),
			data: {
				title: `Заявка № ${req.id} ${
					req?.created_at ? `от ${moment(req.created_at).format(DATE_FORMAT)}` : '-'
				}`,
				address: `${req.company ?? ''} ${req.address ?? ''}`,
				isBold: true,
			},
		})),
	);
