/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { reset, submit, change } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { RequestForm } from './components/request-form';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import {
	createRequestAction,
	getAddressesAction,
	getDictionariesAction,
	getPartnersAction,
	getRequestInfoAction,
	resetStateAction,
	setRequestInfoAction,
	updateRequestAction,
} from './actions';
import {
	getActiveRequestIdSelector,
	getCompanyIdSelector,
	getIsAddAddressModalShownSelector,
	getIsAddContactModalShownSelector,
	getIsAddPartnerModalShownSelector,
	getIsModalShownSelector,
	getIsRequestAddFormOpenSelector,
	getReqNameSelector,
	getRequestFormInitialValuesSelector,
	getRouterQueriesSelector,
} from './selectors';
import { RequestPartnerModal } from './components/partner-form';
import { AddressModal } from './components/address-modal';
import { ContactModal } from './components/contact-modal';
import { REQUEST_FORM_NAME } from './constants';
import { RequestFormExpress } from './components/request-form-express';
import { RequestFormCar } from './components/request-form-car';
import { RequestFormAvia } from './components/request-form-avia';
import cancelModal from '../../utils/cancelModal';
import closeModal from '../../utils/closeModal';

const RequestModalWrapper = ({
	isRequestAddFormOpen,
	onGetPartners,
	onGetDictionaries,
	isModalShown,
	onResetState,
	isAddPartnerModalShown,
	isAddAddressModalShown,
	isAddContactModalShown,
	onGetRequestInfo,
	activeRequestId,
	onFormSubmit,
	onUpdateRequest,
	reqName,
	onCreateRequest,
	onResetForm,
	initialValues,
	onSetRequestInfo,
	companyId,
	onGetAddresses,
	onFieldChange,
	routerQueries,
}) => {
	useInjectSaga({ key: 'requestFormSaga', saga });
	useInjectReducer({ key: 'requestFormReducer', reducer });

	const history = useHistory();

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	useEffect(() => {
		if (isModalShown) {
			onGetDictionaries();

			if (query.get('mode') === 'add') {
				onGetPartners();
			}
		}

		// on create add company creator id
		if (isModalShown && query.get('mode') === 'add') {
			const newPoints = [...(initialValues.points || [])];

			if (!initialValues?.points?.[0]?.partner) {
				newPoints[0] = {
					...(initialValues.points?.[0] || {}),
					partner: {
						id: companyId,
					},
				};

				onGetAddresses({ id: companyId, index: 0 });
			}

			if (!initialValues?.points?.[1]) {
				newPoints[1] = {};
			}

			onSetRequestInfo({ ...initialValues, points: newPoints });
		}

		if (isModalShown && activeRequestId && isEmpty(initialValues)) {
			onGetRequestInfo({ id: activeRequestId, mode: query.get('mode') });
		}

		if (isModalShown && query.get('mode') === 'add') {
			onFieldChange(REQUEST_FORM_NAME, 'mode', 'none');
		}
	}, [isModalShown]);

	const handleModalCancel = async () => {
		cancelModal(query);
		onResetState();
		onResetForm(REQUEST_FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = async () => {
		closeModal(query);
		onResetState();
		onResetForm(REQUEST_FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleFormSubmit = async () => {
		await onFormSubmit(REQUEST_FORM_NAME);
		const path = pathname.split('/').slice(0, 3).join('/');

		if (activeRequestId) {
			const { mode } = routerQueries;
			if (mode === 'recreate') {
				onCreateRequest({
					searchQuery: query,
					redirect: handleModalClose,
					pathname: path,
					mode: 'recreate',
				});
			} else {
				onUpdateRequest({
					id: activeRequestId,
					searchQuery: query,
					redirect: handleModalClose,
					pathname: path,
				});
			}
		} else {
			onCreateRequest({ searchQuery: query, redirect: handleModalClose, pathname: path });
		}
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleFormSubmit();
		}
	};

	if (isAddPartnerModalShown) {
		return <RequestPartnerModal />;
	}

	if (isAddAddressModalShown) {
		return <AddressModal />;
	}

	if (isAddContactModalShown) {
		return <ContactModal />;
	}

	// change form depends on page
	let form = <RequestForm />;
	let title = '<span class="divide">Сборный груз</span>';

	if (pathname.includes('car')) {
		form = <RequestFormCar />;
		title = '<span class="divide">Отдельная машина</span>';
	}

	if (pathname.includes('express')) {
		form = <RequestFormExpress />;
		title = '<span class="divide">Срочная заявка</span>';
	}

	if (pathname.includes('avia')) {
		form = <RequestFormAvia />;
		title = '<span class="divide">Авиа заявка</span>';
	}

	const addButtons = [
		{ onClick: handleFormSubmit, text: 'Добавить', color: 'primary' },
		{ onClick: handleModalClose, text: 'Отмена', color: 'secondary' },
	];

	const saveButtons = [
		{ onClick: handleFormSubmit, text: 'Сохранить', color: 'primary' },
		{ onClick: handleModalCancel, text: 'Отмена', color: 'secondary' },
	];

	let headerText = title;
	let buttons = addButtons;

	if (!isRequestAddFormOpen) {
		headerText = reqName;
		buttons = saveButtons;
		if (query.get('mode') === 'recreate') {
			headerText = title;
			buttons = addButtons;
		}
	}

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={headerText}
			modalContent={form}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'request' }]}
			footerButtons={<ModalFooterButtons rightButtons={buttons} />}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	routerQueries: getRouterQueriesSelector(),
	isAddPartnerModalShown: getIsAddPartnerModalShownSelector(),
	isAddAddressModalShown: getIsAddAddressModalShownSelector(),
	isAddContactModalShown: getIsAddContactModalShownSelector(),
	activeRequestId: getActiveRequestIdSelector(),
	isRequestAddFormOpen: getIsRequestAddFormOpenSelector(),
	reqName: getReqNameSelector(),
	initialValues: getRequestFormInitialValuesSelector(),
	companyId: getCompanyIdSelector(),
});

const mapDispatchToProps = {
	onGetPartners: getPartnersAction,
	onGetDictionaries: getDictionariesAction,
	onResetState: resetStateAction,
	onGetRequestInfo: getRequestInfoAction,
	onFormSubmit: submit,
	onUpdateRequest: updateRequestAction,
	onCreateRequest: createRequestAction,
	onResetForm: reset,
	onSetRequestInfo: setRequestInfoAction,
	onGetAddresses: getAddressesAction,
	onFieldChange: change,
};

RequestModalWrapper.propTypes = {
	isRequestAddFormOpen: PropTypes.bool,
	onGetPartners: PropTypes.func.isRequired,
	onGetDictionaries: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onResetState: PropTypes.func.isRequired,
	isAddPartnerModalShown: PropTypes.bool.isRequired,
	isAddAddressModalShown: PropTypes.bool.isRequired,
	isAddContactModalShown: PropTypes.bool.isRequired,
	onGetRequestInfo: PropTypes.func.isRequired,
	activeRequestId: PropTypes.string,
	onFormSubmit: PropTypes.func.isRequired,
	onUpdateRequest: PropTypes.func.isRequired,
	reqName: PropTypes.string.isRequired,
	onCreateRequest: PropTypes.func.isRequired,
	onResetForm: PropTypes.func.isRequired,
	initialValues: PropTypes.object.isRequired,
	onSetRequestInfo: PropTypes.func.isRequired,
	companyId: PropTypes.number,
	onGetAddresses: PropTypes.func.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	routerQueries: PropTypes.object,
};

const RequestModal = connect(mapStateToProps, mapDispatchToProps)(RequestModalWrapper);

export default RequestModal;
