import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectContactsDomain = state => state.contactsReducer || initialState;

export const selectRouterDomain = state => state.router || {};

export const getContactsSelector = () =>
	createSelector(selectContactsDomain, ({ contacts }) => {
		return contacts.map(item => ({
			...item,
			phone: item.phone ? `${item.phone}${item.extension ? `,${item.extension}` : ''}` : '-',
		}));
	});

export const getIsContactsLoadingSelector = () =>
	createSelector(selectContactsDomain, ({ isContactsLoading }) => isContactsLoading);

export const getContactsSearchValueSelector = () =>
	createSelector(selectContactsDomain, ({ contactsSearchValue }) => contactsSearchValue);

export const getRawPaginationSelector = () => createSelector(selectContactsDomain, ({ pagination }) => pagination);

export const getPaginationSelector = () =>
	createSelector(selectContactsDomain, ({ pagination }) => {
		if (!pagination) {
			return { totalPages: 1 };
		}

		return {
			currentPage: pagination.current_page,
			totalPages: pagination.last_page,
			perPage: pagination.per_page,
			total: pagination.total,
			from: pagination.current_page * pagination.per_page - (pagination.per_page - 1),
			to:
				pagination.current_page === pagination.last_page
					? pagination.total
					: pagination.current_page * pagination.per_page,
			isNextItem: true,
			isPrevItem: true,
			isLastItem: false,
			isFirstItem: false,
		};
	});

export const getCurrentPathnameSelector = () =>
	createSelector(selectRouterDomain, ({ location: { pathname } }) => pathname);
