import { CustomCell } from '../../components/custom-cell';
import { MarkerCell } from './components/marker-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const ADDRESSES_TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: CustomCell },
	{ name: 'Название', id: 'name', width: '689px', customComponent: CustomCell },
	{ name: 'Адрес', id: 'address', width: '689px' },
	{ name: '', id: 'marker', width: '229px', customComponent: MarkerCell },
];
