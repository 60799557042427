export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const FORM_NAME = 'requests-list-form';

export const BUTTONS_NAMES = {
	take: 'В работу',
	undo: 'Удалить из работы',
	revoke: 'Отозвать',
	rejects: 'Отказать всем',
	select: 'Выбрать перевозчика',
	agree: 'Согласовать дату',
	approve: 'Подтвердить',
	bet: 'Ставка',
	cancel: 'Отмена',
	close: 'Аннулировать заявку',
	confirm: 'Подтвердить',
	destroy: 'Удалить',
	done: 'Груз доставлен',
	fetch: 'Груз забран',
	generateDoc: 'Сформировать документы',
	sendDoc: 'Направить документы заказчику',
	setCar: 'Назначить машину',
	setDate: 'Назначить дату',
	setDriver: 'Назначить водителя',
	signDoc: 'Подписать и отправить',
	store: 'Создать',
	unbet: 'Отозвать ставку',
	update: 'Изменить',
	recreate: 'Пересоздать',
	deny: 'Отклонить дату',
	setDone: 'Груз доставлен',
	setDocuments: 'Прикрепить документы',
	setDocumentsPoint: 'Прикрепить документы',
	setFlight: 'Уточнить рейс',
	setOffers: 'Посмотреть ставки',
};

export const BUTTONS_STYLES = {
	take: 'success',
	undo: 'warning',
	revoke: 'warning',
	rejects: 'warning',
	select: 'success',
	agree: 'success',
	approve: 'success',
	bet: 'success',
	cancel: 'warning',
	close: 'warning',
	confirm: 'success',
	destroy: 'warning',
	done: 'success',
	fetch: 'success',
	generateDoc: 'success',
	sendDoc: 'success',
	setCar: 'success',
	setDate: 'success',
	setDriver: 'success',
	signDoc: 'success',
	store: 'success',
	unbet: 'warning',
	update: 'success',
	recreate: 'success',
	deny: 'warning',
	setDone: 'success',
	setDocuments: 'success',
	setDocumentsPoint: 'success',
	setFlight: 'success',
	setOffers: 'success',
};

export const BUTTONS_TO_ROUTES = {
	generateDoc: 'generateDoc',
	sendDoc: 'sendDoc',
	setCar: 'setCar',
	setDate: 'setDate',
	setDriver: 'setDriver',
	signDoc: 'signDoc',
	setDocuments: 'setDocuments',
	setDocumentsPoint: 'setDocumentsPoint',
};

export const BUTTONS_TO_MODALS = {
	select: 'carrier_change',
	setCar: 'car_change',
	setDate: 'date_cargo_collection',
	setDriver: 'driver_change',
	update: 'request',
	recreate: 'request',
	deny: 'refusal_reason',
	setDone: 'cargo_delivery',
	setDocuments: 'document_attachment_form',
	setDocumentsPoint: 'cargo_delivery',
	setFlight: 'specify_flight',
	setOffers: 'carrier_change',
};
