import { put, takeEvery, call, select, take, fork, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { DISMISS_TIME } from '../../api/constants';
import {
	GET_REQS,
	UPDATE_REQ,
	setPaginationAction,
	setReqsAction,
	GET_REQS_WITH_FILTERS,
	setCountAction,
	DELETE_REQ,
	TAKE_REQ,
	setIsReqsLoadingAction,
	getReqsAction,
	setTimerAction,
} from './actions';
import {
	deleteReqRequest,
	getReqsFilterRequest,
	getReqsRequest,
	getRequestsCountRequest,
	takeReqRequest,
	updateMyReqWithPayloadRequest,
} from '../../api/requests';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { getIsTimerVisibleSelector, getPathnameSelector, getRawPaginationSelector, getReqsSelector } from './selectors';
import { setAnalyticsAction, setFilterOptionsAction } from '../reqs-filter/actions';
import {
	createReqBadgesUpdateChannels,
	createReqUpdateChannels,
	createReqsUpdateChannels,
	createReqTableUpdateChannels,
	createTimerUpdateChannels,
} from '../../api/sockets';
import { getCompanyIdSelector } from '../profile/selectors';
import { getRequestInfoWsRequest } from '../../api/reqs-requests';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { errorHandler } from '../../api/utils';

export function* getReqsSaga({ payload: { id, searchQuery, scrollToTop, noLoading } }) {
	try {
		// no loading needed if socket
		if (!noLoading) {
			yield put(setIsReqsLoadingAction(true));
			yield put(setReqsAction([]));
		}

		const [{ data, meta, message, toast, timer }, { filter, analytics }, badges] = yield all([
			call(getReqsRequest, id, searchQuery),
			call(getReqsFilterRequest, id, searchQuery),
			call(getRequestsCountRequest),
		]);

		yield put(setCountAction(badges.data));

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setReqsAction(
					data?.map(req => ({
						...req,
						link: `${window.location.pathname}?${
							searchQuery ? `${searchQuery}&` : ''
						}modal=reqs_details&id=${req.id}`,
						additionalPoints: req.points.length - 2,
						renderPoints: [req.points[0], req.points.at(-1)],
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}

			yield put(setTimerAction(timer));

			if (scrollToTop) {
				scrollToTop();
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}

		if (filter) {
			const filters = {
				...(filter || {}),
				mode: [...(filter?.mode || []), { name: 'Отсутствует', id: 'none' }],
				...(filter?.delivery_status ? { deliveryStatus: filter.delivery_status } : {}),
			};

			yield put(setFilterOptionsAction(filters));
		}

		if (analytics) {
			const query = new URLSearchParams(searchQuery);

			const cities = query.getAll('filter.cities[]');

			const newAnalytics =
				analytics?.map(el => {
					const newCities = el.cities.map(city => {
						return {
							...city,
							isChecked: cities.includes(city.id),
							weight: city.weight.toFixed(2),
							volume: city.volume.toFixed(2),
							sum: city.sum ? city.sum.toFixed(2) : null,
						};
					});

					const isSomeCitiesChecked = newCities.some(city => city.isChecked);

					if (isSomeCitiesChecked && !query.has('filter.cities[]', el.id)) {
						query.append('filter.cities[]', el.id);
					}

					if (!isSomeCitiesChecked && query.has('filter.cities[]', el.id)) {
						query.delete('filter.cities[]', el.id);
					}

					return {
						...el,
						isChecked: isSomeCitiesChecked,
						cities: newCities,
						weight: el.weight.toFixed(2),
						volume: el.volume.toFixed(2),
						sum: el.sum ? el.sum.toFixed(2) : null,
					};
				}) || [];

			yield put(setAnalyticsAction(newAnalytics));
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsReqsLoadingAction(false));
	}
}

export function* updateRequestSaga({ payload: { id, field, searchQuery, redirect, body } }) {
	try {
		const { data, toast, message } = yield call(updateMyReqWithPayloadRequest, id, `/${field}`, body);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const reqs = yield select(getReqsSelector());

			let newReqs = [];

			if (data.routes.includes(window.location.pathname)) {
				const updatedReq = {
					...data,
					link: `${window.location.pathname}?${searchQuery ? `${searchQuery}&` : ''}modal=reqs_details&id=${
						data.id
					}`,
					additionalPoints: data.points.length - 2,
					renderPoints: [data.points[0], data.points.at(-1)],
				};

				newReqs = reqs.map(req => {
					return req.id === data.id ? updatedReq : req;
				});
			} else {
				newReqs = reqs.filter(req => {
					return req.id !== data.id;
				});

				const pagination = yield select(getRawPaginationSelector());

				if (pagination) {
					yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
				}
			}

			yield put(setReqsAction(newReqs));

			if (redirect) {
				redirect();
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* deleteRequestSaga({ payload: { id, redirect, pathname } }) {
	try {
		const { data, toast, message } = yield call(deleteReqRequest, id, pathname);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const reqs = yield select(getReqsSelector());

			const newReqs = reqs.filter(req => {
				return req.id !== data.id;
			});

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			yield put(setReqsAction(newReqs));

			if (redirect) {
				redirect();
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* takeReqSaga({ payload: { id, searchQuery, redirect } }) {
	try {
		const { data, toast, message } = yield call(takeReqRequest, id);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const reqs = yield select(getReqsSelector());

			let newReqs = [];

			if (data.routes.includes(window.location.pathname)) {
				const updatedReq = {
					...data,
					link: `${window.location.pathname}?${searchQuery ? `${searchQuery}&` : ''}modal=reqs_details&id=${
						data.id
					}`,
					additionalPoints: data.points.length - 2,
					renderPoints: [data.points[0], data.points.at(-1)],
				};

				newReqs = reqs.map(req => {
					return req.id === data.id ? updatedReq : req;
				});
			} else {
				newReqs = reqs.filter(req => {
					return req.id !== data.id;
				});

				const pagination = yield select(getRawPaginationSelector());

				if (pagination) {
					yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
				}
			}

			yield put(setReqsAction(newReqs));

			if (redirect) {
				redirect();
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* getRequestInfoSaga(id) {
	try {
		const { data, message } = yield call(getRequestInfoWsRequest, id);

		if (data) {
			const reqs = yield select(getReqsSelector());

			const query = new URLSearchParams(window.location.search);

			if (!data.routes.includes(window.location.pathname)) {
				// remove from list if not in routes
				yield put(setReqsAction(reqs.filter(el => el.id !== data.id)));
			} else {
				yield put(
					setReqsAction(
						reqs?.map(req => {
							if (req.id === data.id) {
								return {
									...data,
									link: `${window.location.pathname}?${
										removeModalQuery(query.toString())
											? `${removeModalQuery(query.toString())}&`
											: ''
									}modal=reqs_details&id=${req.id}`,
									additionalPoints: data.points.length - 2,
									renderPoints: [data.points[0], data.points.at(-1)],
								};
							}

							return req;
						}),
					),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* getRequestsCountSaga() {
	try {
		const { data, message } = yield call(getRequestsCountRequest);

		if (data) {
			yield put(setCountAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* socketReqUpdateSaga() {
	try {
		const companyId = yield select(getCompanyIdSelector());

		const channel = yield call(() => createReqUpdateChannels(companyId));

		if (!channel) return;

		while (true) {
			const request = yield take(channel);

			const reqs = yield select(getReqsSelector());

			const updatedRequest = reqs.find(req => req.id === request.id);

			if (updatedRequest) {
				yield fork(getRequestInfoSaga, request.id);
			}

			yield fork(getRequestsCountSaga);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* socketReqsUpdateSaga() {
	const companyId = yield select(getCompanyIdSelector());

	const channel = yield call(() => createReqsUpdateChannels(companyId));

	if (!channel) return;

	while (true) {
		const data = yield take(channel);

		if (data.routes.includes(window.location.pathname)) {
			const reqs = yield select(getReqsSelector());

			const updatedRequests = reqs.reduce((acc, req) => {
				if (data.ids.includes(req.id)) {
					acc.push(req.id);
				}

				return acc;
			}, []);

			if (updatedRequests.length) {
				yield all(
					updatedRequests.map(id => {
						return fork(getRequestInfoSaga, id);
					}),
				);
			}
		}

		yield fork(getRequestsCountSaga);
	}
}

export function* socketReqTableUpdateSaga() {
	const companyId = yield select(getCompanyIdSelector());

	const channel = yield call(() => createReqTableUpdateChannels(companyId));

	if (!channel) return;

	while (true) {
		const data = yield take(channel);

		if (data.routes.includes(window.location.pathname)) {
			const query = new URLSearchParams(window.location.search);

			yield put(
				getReqsAction({
					id: window.location.pathname,
					searchQuery: removeModalQuery(query.toString()),
					noLoading: true,
				}),
			);
		}

		yield fork(getRequestsCountSaga);
	}
}

export function* socketReqCountUpdateSaga() {
	const companyId = yield select(getCompanyIdSelector());

	const channel = yield call(() => createReqBadgesUpdateChannels(companyId));

	if (!channel) return;

	while (true) {
		const data = yield take(channel);

		yield put(setCountAction(data.data));
	}
}

export function* socketTimerUpdateSaga() {
	const companyId = yield select(getCompanyIdSelector());

	const channel = yield call(() => createTimerUpdateChannels(companyId));

	if (!channel) return;

	while (true) {
		const { data } = yield take(channel);

		const isTimerVisible = yield select(getIsTimerVisibleSelector());

		if (isTimerVisible) {
			const pathname = yield select(getPathnameSelector());

			const value = Object.entries(data).reduce((acc, [key, value]) => {
				if (pathname.includes(key) && value) {
					acc.push(value);
				}

				return acc;
			}, []);

			if (value[0]) {
				yield put(setTimerAction(value[0]));
			}
		}
	}
}

export default function* requestsListContainerSaga() {
	yield takeEvery(GET_REQS, getReqsSaga);
	yield takeEvery(GET_REQS_WITH_FILTERS, getReqsSaga);
	yield takeEvery(UPDATE_REQ, updateRequestSaga);
	yield takeEvery(DELETE_REQ, deleteRequestSaga);
	yield takeEvery(TAKE_REQ, takeReqSaga);
}
