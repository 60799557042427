import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Loader } from 'semantic-ui-react';

export const RequestsInfoView = ({ isInformationLoading, reqInfo }) => {
	return (
		<div className="requests-info">
			<header className="requests-info__header">Информация о заявках</header>
			{isInformationLoading ? (
				<Loader active size="big" />
			) : (
				<>
					<div className="requests-info__row">
						<div className="requests-info__row-cell requests-info__row-cell_green">{reqInfo.in_work}</div>
						<p className="requests-info__row-text">заявок в работе</p>
					</div>
					<div className="requests-info__row">
						<div className="requests-info__row-cell requests-info__row-cell_blue">{reqInfo.on_way}</div>
						<p className="requests-info__row-text">заявок в пути</p>
					</div>
					<div className="requests-info__row">
						<div className="requests-info__row-cell requests-info__row-cell_blue">{reqInfo.expired}</div>
						<p className="requests-info__row-text">просроченные</p>
					</div>
				</>
			)}
		</div>
	);
};

RequestsInfoView.propTypes = {
	isInformationLoading: PropTypes.bool.isRequired,
	reqInfo: PropTypes.shape({
		in_work: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		on_way: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		expired: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}).isRequired,
};
