/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { reset } from 'redux-form';
import { getSpecifyFlightAction, sendFlightAction, setSpecifyFlightInfoAction } from './actions';
import { getIsModalShownSelector, getIsFormSendingSelector } from './selectors';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { SpecifyFlightForm } from './components/specify-flight-form';
import closeModal from '../../utils/closeModal';

const SpecifyFlightModalWrapper = ({
	onGetSpecifyFlight,
	isModalShown,
	onSendFlight,
	onResetForm,
	isFormSending,
	onSetSpecifyFlightInfo,
}) => {
	useInjectSaga({ key: 'specifyFlightModalSaga', saga });
	useInjectReducer({ key: 'specifyFlightModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetSpecifyFlight(query.get('id'));
		}
	}, [isModalShown]);

	const handleModalCancel = () => {
		query.set('modal', 'reqs_details');
		onResetForm(FORM_NAME);
		onSetSpecifyFlightInfo(null);
		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		closeModal(query);
		onResetForm(FORM_NAME);
		onSetSpecifyFlightInfo(null);
		history.push(`${pathname}?${query}`);
	};

	const handleFormSubmit = async () => {
		onSendFlight({
			redirect: handleModalCancel,
			id: query.get('id'),
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText="Уточните аэропорт, номер и дату рейса"
			modalContent={<SpecifyFlightForm />}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'specify_flight' }]}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleFormSubmit,
							text: 'Сохранить',
							color: 'success',
							loading: isFormSending,
							disabled: isFormSending,
						},
						{
							onClick: handleModalCancel,
							text: 'Закрыть',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	isFormSending: getIsFormSendingSelector(),
});

const mapDispatchToProps = {
	onGetSpecifyFlight: getSpecifyFlightAction,
	onSendFlight: sendFlightAction,
	onResetForm: reset,
	onSetSpecifyFlightInfo: setSpecifyFlightInfoAction,
};

const SpecifyFlightModal = connect(mapStateToProps, mapDispatchToProps)(SpecifyFlightModalWrapper);

SpecifyFlightModalWrapper.propTypes = {
	onGetSpecifyFlight: PropTypes.func.isRequired,
	onSendFlight: PropTypes.func.isRequired,
	isFormSending: PropTypes.bool.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onResetForm: PropTypes.func.isRequired,
	onSetSpecifyFlightInfo: PropTypes.func.isRequired,
};

export default SpecifyFlightModal;
