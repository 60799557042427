import React from 'react';
import PropTypes from 'prop-types';

export const ErrorComponent = ({ meta }) => {
	if (meta.error && meta.touched) {
		if (typeof meta.error === 'string') {
			return <div className="req-form__error-message">{meta.error}</div>;
		}
		if (Array.isArray(meta.error)) {
			return meta.error.map(error => (
				<div key={error} className="req-form__error-message">
					{error}
				</div>
			));
		}
	}

	return null;
};

ErrorComponent.propTypes = {
	meta: PropTypes.object.isRequired,
};
