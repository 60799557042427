export default function cancelModal(query) {
	if (query.get('fromModal')) {
		query.set('modal', query.get('fromModal'));
	} else {
		query.delete('modal');
		query.delete('id');
	}
	query.delete('fromModal');
	query.delete('pointId');
}
