/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset, submit } from 'redux-form';
import { changeDriverAction, getDriversAction, getRequestInfoAction, resetStateAction } from './actions';
import {
	getDriversOptionsSelector,
	getIsButtonsDisabledSelector,
	getIsDriversLoadingSelector,
	getIsModalShownSelector,
	getModalStatusesSelector,
	getReqNameSelector,
} from './selectors';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { DriverChangeModalForm } from './components/driver-change-modal-form';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import { validate } from './utils';
import cancelModal from '../../utils/cancelModal';
import closeModal from '../../utils/closeModal';

const DriverChangeModalWrapper = ({
	onResetState,
	isModalShown,
	onChangeDriver,
	onGetRequestInfo,
	reqName,
	modalStatuses,
	onResetForm,
	isButtonsDisabled,
	isDriversLoading,
	driversOptions,
	onFormSubmit,
	onGetDrivers,
}) => {
	useInjectSaga({ key: 'driverChangeModalSaga', saga });
	useInjectReducer({ key: 'driverChangeModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetRequestInfo(query.get('id'));
			onGetDrivers(query.get('id'));
		}
	}, [isModalShown]);

	const handleModalCancel = () => {
		cancelModal(query);
		onResetState();
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		closeModal(query);
		onResetState();
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		onChangeDriver({ redirect: handleModalClose, id: query.get('id'), searchQuery: query.toString() });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={reqName}
			modalContent={<DriverChangeModalForm isDriversLoading={isDriversLoading} driversOptions={driversOptions} />}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'driver_change' }]}
			headerStatuses={modalStatuses}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleFormSubmit,
							text: 'Назначить',
							color: 'primary',
							disabled: isButtonsDisabled,
						},
						{
							onClick: handleModalCancel,
							text: 'Отмена',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(DriverChangeModalWrapper);

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	reqName: getReqNameSelector(),
	modalStatuses: getModalStatusesSelector(),
	isButtonsDisabled: getIsButtonsDisabledSelector(),
	isDriversLoading: getIsDriversLoadingSelector(),
	driversOptions: getDriversOptionsSelector(),
});

const mapDispatchToProps = {
	onResetState: resetStateAction,
	onChangeDriver: changeDriverAction,
	onGetRequestInfo: getRequestInfoAction,
	onResetForm: reset,
	onGetDrivers: getDriversAction,
	onFormSubmit: submit,
};

const DriverChangeModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

DriverChangeModalWrapper.propTypes = {
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onChangeDriver: PropTypes.func.isRequired,
	onGetRequestInfo: PropTypes.func.isRequired,
	reqName: PropTypes.string.isRequired,
	onResetForm: PropTypes.func.isRequired,
	isButtonsDisabled: PropTypes.bool.isRequired,
	isDriversLoading: PropTypes.bool.isRequired,
	driversOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
			value: PropTypes.number.isRequired,
		}),
	).isRequired,
	modalStatuses: PropTypes.arrayOf(PropTypes.shape({ color: PropTypes.string, name: PropTypes.string })).isRequired,
	onGetDrivers: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default DriverChangeModal;
